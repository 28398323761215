import React, { useEffect, useState } from "react";

import { Button, List, Segmented, Table } from "antd";
import { MainTable } from "components";
function KhorooTable({ data, className = "", title }) {
  const [viewMode, setViewMode] = useState("table");
  const [totalCount, setTotal] = useState(
    0
    // data.reduce((a, b) => a.cnt + b.cnt, 0)
  );
  // const [data, setData] = useState(dataService); ///data.reduce((a, b) => a.cnt + b.cnt, 0);
const columns = [
  {
    title: "Хороо",
    dataIndex: "soum",
    key: "soum",
  },
  {
    title: "Нийт",
    dataIndex: "Total",
    key: "Total",
    render: (text, row) => <div>{Intl.NumberFormat().format(text)}</div>,
    sorter: (a, b) => a.Total - b.Total,
  },
  {
    title: "Улаан",
    dataIndex: "redCount",
    key: "redCount",
    render: (text, row) => <div>{Intl.NumberFormat().format(text)}</div>,
    sorter: (a, b) => a.redCount - b.redCount,
  },
  {
    title: "Цэнхэр",
    dataIndex: "blueCount",
    key: "blueCount",
    render: (text, row) => <div>{Intl.NumberFormat().format(text)}</div>,
    sorter: (a, b) => a.blueCount - b.blueCount,
  },
  {
    title: "Саарал",
    dataIndex: "grayCount",
    key: "grayCount",
    render: (text, row) => <div>{Intl.NumberFormat().format(text)}</div>,
    sorter: (a, b) => a.grayCount - b.grayCount,
  },
  {
    title: "Дэмжинэ",
    dataIndex: "suppCount",
    key: "suppCount",
    render: (text, row) => <div>{Intl.NumberFormat().format(text)}</div>,
    sorter: (a, b) => a.suppCount - b.suppCount,
  },
  {
    title: "Дэмжихгүй",
    dataIndex: "notSuppCount",
    key: "notSuppCount",
    render: (text, row) => <div>{Intl.NumberFormat().format(text)}</div>,
    sorter: (a, b) => a.notSuppCount - b.notSuppCount,
  },
  {
    title: "Шийдээгүй",
    dataIndex: "notDecision",
    key: "notDecision",
    render: (text, row) => <div>{Intl.NumberFormat().format(text)}</div>,
    sorter: (a, b) => a.notDecision - b.notDecision,
  },
];

  return (
    <div
      className={` bg-white rounded-lg shadow p-3 flex flex-col ${className}`}
    >
      <div className="flex gap-5">
        <div className="border rounded-lg overflow-hidden flex-1">
          {/* <div className="py-2 px-2 border-b">Дундаж онш бичилт</div> */}
          <MainTable
            dataTable={
              data 
            }
            columns={columns}
            title={title}
          />
        </div>
      </div>
    </div>
  );
}

export default KhorooTable;
