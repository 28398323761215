import {
  Employee,
  ListPeople,
  Dashboard,
} from "modules/admin";

export default [
  {
    name: "ListPeople",
    path: "/list",
    element: <ListPeople />,
    role: ["Админ", "Удирдлага", "Менежер", "Ахлагч"],
  },
  {
    name: "Addmission Dashboard",
    path: "/",
    element: <Dashboard />,
    role: ["Админ", "Удирдлага", "Менежер", "Ахлагч"],
  },
  {
    name: "Addmission Dashboard",
    path: "/dashboard",
    element: <Dashboard />,
    role: ["Админ", "Удирдлага", "Менежер", "Ахлагч"],
  },
  // Хүний нөөц
  {
    name: "Ажилчид",
    path: "/employee",
    role: ["Админ", "Удирдлага", "Менежер"],
    element: <Employee />,
  },
];
