import React, { useEffect, useState, useContext } from "react";
import { Button, Form as AntForm, Table, Tooltip,Input,Select, Tag, Modal } from "antd";
import { SearchOutlined, ClearOutlined, EditOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";

import { Link } from "react-router-dom";

import { AuthContext } from "contexts";

function Employee() {
  const authContext = useContext(AuthContext);
  const hospitalId = authContext.state.userInfo.profile.hospitalId;
  const [hospitals, setHospitals] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [filterForm] = AntForm.useForm();
  const [roles, setRoles] = useState([]);
  const [editData, setEditData] = useState(null);
  const [open, setOpen] = useState(false);

  const [form] = AntForm.useForm();

  useEffect(() => {
    fetchData();
  }, [currentPage]);


  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/systemusers?pageindex=${currentPage}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };


  const columns = [
    {
      title: "No",
      dataIndex: "No",
      key: "No",
      width: 40,
      align: "center",
      render: (text, row, i) => <span>{currentPage + i + 1}</span>,
    },
    {
      title: "Овог Нэр",
      dataIndex: "lastName",
      key: "lastName",
      render: (text, row) => (
        <span>
          {text} {row.firstName}
        </span>
      ),
    },
    {
      title: "Утасны дугаар",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "И-Мэйл хаяг",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Албан тушаал",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Хэсэг",
      dataIndex: "sectionId",
      key: "sectionId",
    },
    {
      title: "Хороо",
      dataIndex: "khorooId",
      key: "khorooId",
    },
    {
      title: "Системийн эрх",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      render: (text, row) => (
        <Tag color={text === "Идэвхитэй" ? "success" : "default"}>{text}</Tag>
      ),
    },
    {
      title: "",
      dataIndex: "action",
      key: "action",
      width: 80,
      render: (text, row) => (
        <Button size="small" onClick={() => handleEdit(row)}>
          <div className="flex items-center gap-2">
            <EditOutlined /> Засах
          </div>
        </Button>
      ),
    },
  ];

  const handleAdd = () => {
    setEditData(null);
    setOpen(true);
  };

  const handleEdit = (row) => {
    setEditData(row);
    setOpen(true);
  };
  const handleFilterSubmit = (values) => {
    setLoading(true);
    instance({
      method: "get",
      url: `/systemusers?pageindex=0&pagesize=${20}&departmentId=${
        values.departmentId ? values.departmentId : ""
      }&card_no=${values.cardNo ? values.cardNo : ""}&name=${
        values.name ? values.name : ""
      }&phone=${values.phone ? values.phone : ""}`,
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const fields = [
    {
      label: "Овог",
      name: "lastName",
      rules: [{ required: true, message: "Овог оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нэр",
      name: "firstName",
      rules: [{ required: true, message: "Нэр оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },

    {
      label: "Албан тушаал",
      name: "position",
      rules: [{ required: true, message: "Албан тушаал оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Системийн эрх",
      name: "role",
      type: "select",
      rules: [{ required: true, message: "Системийн эрх оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        // "Админ", "Удирдлага","Менежер", "Ахлагч"
        options: [
          { value: "Админ", label: "Админ" },
          { value: "Удирдлага", label: "Удирдлага" },
          { value: "Менежер", label: "Менежер" },
          { value: "Ахлагч", label: "Ахлагч" },
        ],
      },
    },
    {
      label: "Утасны дугаар",
      name: "mobile",
      rules: [{ required: true, message: "Утасны дугаар оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хэсэг",
      name: "sectionId",
      type: "number",
    
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Хороо",
      name: "khorooId",
      type: "number",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "И-Майл хаяг",
      name: "email",
      rules: [{ required: true, message: "И-Майл хаяг оруулна уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Нууц үг",
      name: "password",
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
      },
    },
    {
      label: "Төлөв",
      name: "status",
      type: "select",
      rules: [{ required: true, message: "Төлөв cонгоно уу!" }],
      className: "col-span-12 md:col-span-6 mb-0",
      inputProps: {
        className: "w-full",
        options: [
          { label: "Идэвхитэй", value: "Идэвхитэй" },
          { label: "Идэвхигүй", value: "Идэвхигүй" },
        ],
      },
    },
  ];

  const handleSubmit = (values) => {
    setLoading(true);
    if (editData) {
      instance({
        method: "put",
        url: `/systemusers`,
        data: {
          ...values,
          id: editData.id,
        },
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    } else {
      console.log(values);
      instance({
        method: "post",
        url: `/systemusers`,
        data: values,
      })
        .then((res) => {
          fetchData();
          handleCloseModal();
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    }
  };

  const handleCloseModal = () => {
    setEditData(null);
    setOpen(false);
    form.resetFields();
  };

  return (
    <div className="flex flex-col gap-4 px-4 pt-4">
    
      <div className={"border bg-white border-gray-200 rounded-lg shadow"}>
        <MainTable
          dataTable={data}
          columns={columns}
          setCurrentPage={setCurrentPage}
          loading={loading}
          pagination={false}
          title={"Ажилчид"}
          handleAdd={handleAdd}
        />
        {/* <Table
            size='small'
            rowKey={(row) => row.key}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' :  'table-row-light'}
            className='px-4'
            dataSource={data}
            columns={columns} 
            loading={loading} 
            title={"Харилцагч"}
            handleAdd={handleAdd}
            pagination={{
              showSizeChanger: false,
              total: data.totalItems,
              pageSize: 20,
              onChange: (page) => {
                setCurrentPage(page-1)},
            }}
          /> */}
      </div>
      <Modal
        title={editData ? "Ажилтны мэдээлэл засах" : "Шинэ ажилтан"}
        open={open}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        confirmLoading={loading}
        onOk={form.submit}
        onCancel={loading ? null : handleCloseModal}
        cancelButtonProps={{ disabled: loading }}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmit}
          editData={editData}
          // isEdit={isEdit}
          className="gap-4"
        />
      </Modal>
    </div>
  );
}

export default Employee;
