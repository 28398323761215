import {
  Button,
  Checkbox,
  Col,
  Input,
  Modal,
  Row,
  Table,
  Header,
  Affix,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  FilterOutlined,
  ControlOutlined,
  PlusOutlined,
} from "@ant-design/icons";

const MainTable = ({
  dataTable = [],
  columns,
  setCurrentPage,
  loading,
  expandable = null,
  handleAdd,
  rowSelectionShow = false,
  pagination = false,
  setSelectedKeys,
  selectedKeys,
  title,
  borderHide = false,
  containerClass = "",
  scroll,
  pageSize=0,
  ...restProps
}) => {
  const [mainColumns, setMainColumns] = useState([]);

  useEffect(() => {
    let columnItem = [];
    columns?.map((fooValue) => {
      columnItem.push({
        ...fooValue,
        ...renderFilter(fooValue),
      });
    });
    setMainColumns(columnItem);
  }, [columns]);

  const rowSelections = {
    type: "checkbox",
    selectedRowKeys: selectedKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedKeys(selectedRowKeys);
    },
  };

  const renderFilter = (_data) => {
    switch (_data?.filterType) {
      case "select":
        return {
          filterMode: "menu",
          filterSearch: true,
          onFilter: (value, record) => {
            return parseInt(record[_data.dataIndex].id) === value;
          },
          filterIcon: () => {
            return <FilterOutlined />;
          },
        };
      case "status":
        return {
          filterMode: "menu",
          onFilter: (value, record) => {
            return record[_data.dataIndex] === value;
          },
          filterIcon: () => {
            return <FilterOutlined />;
          },
        };
      case "search":
        return {
          filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
          }) => {
            return (
              <div className="flex flex-col rounded-lg p-2">
                <Input
                  autoFocus
                  placeholder={`Search ${_data.title}`}
                  value={selectedKeys[0]}
                  onChange={(e) => {
                    setSelectedKeys(e.target.value ? [e.target.value] : []);
                    confirm({ closeDropdown: false });
                  }}
                  onPressEnter={() => {
                    confirm();
                  }}
                  onBlur={() => {
                    confirm();
                  }}
                />
              </div>
            );
          },
          // sortDirections: ['descend', 'ascend'],
          // sorter: (value, record) => {
          //   return value[_data.dataIndex].localeCompare(record[_data.dataIndex])
          // },
          filterIcon: () => {
            return <SearchOutlined />;
          },
          onFilter: (value, record) => {
            return record[_data.dataIndex]
              ? record[_data.dataIndex]
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase())
              : "";
          },
        };
      default:
        return <></>;
    }
  };
  return (
    <div
      className={`bg-white ${
        borderHide ? "" : "border border-gray-200 rounded-lg shadow"
      } ${containerClass}`}
    >
      {(title || handleAdd) && (
        <div className={"flex flex-row py-3 px-3 items-center justify-between"}>
          {title && <div className="text-[16px]">{title}</div>}
          {handleAdd && (
            <Affix offsetTop={80}>
              <Button
                type="primary"
                size="small"
                className=" flex items-center"
                onClick={handleAdd}
              >
                <PlusOutlined /> Нэмэх
              </Button>
            </Affix>
          )}
        </div>
      )}

      <Table
        {...restProps}
        className={title || handleAdd ? "border-t px-4" : "px-4"}
        rowKey={(row) => row.id}
        rowClassName={(record, index) =>
          index % 2 === 0 ? "table-row-dark" : "table-row-light"
        }
        columns={mainColumns}
        dataSource={dataTable}
        loading={loading}
        size="large"
        scroll={scroll ? scroll : { x: "auto" }}
        expandable={expandable}
        rowSelection={rowSelectionShow && rowSelections}
        rowHoverBg="#bae0ff"
        pagination={
          pagination && {
            total: pagination.total,
            pageSize: pageSize,
            onChange: (e) => setCurrentPage((e - 1) * pageSize),
            showSizeChanger: dataTable.length > pageSize,
          }
        }
      />
    </div>
  );
};

export default MainTable;
