import React, { useState, useContext, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { Button, Modal, Form as AntForm, Menu, Badge } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "contexts";
// import logo from 'assets/images/score-logo.png'
import { Form } from "components";
import { instance } from "utils/axios";
import ls from "utils/ls";
import { MenuOutlined, CloseOutlined } from "@ant-design/icons";
import menu from "../../menu";
import moment from "moment";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const { state, action } = React.useContext(AuthContext);
  const [currentKey, setCurrentKey] = useState("");
  const [roleMenu, setRoleMenu] = useState([]);
  const [form] = AntForm.useForm();

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    setCurrentKey(ls.get("currentMenuKey"));
    let tmp = [];
    menu.map((item) => {
      if (item?.role.includes(state.userInfo?.profile.role)) {
        tmp.push(item);
      }
    });
    setRoleMenu(tmp);
  }, []);

  const handleLogout = () => {
    authContext.action.logout();
    ls.remove("userInfo");
    navigate("/login");
  };

  const fields = [
    {
      label: "Хуучин нууц үг",
      name: "oldpassword",
      type: "password",
      className: "col-span-12 mb-0",
      rules: [{ required: true, message: "Хуучин нууц үг оруулна уу" }],
    },
    {
      label: "Шинэ нууц үг",
      name: "newpassword",
      type: "password",
      className: "col-span-12 mb-0",
      rules: [{ required: true, message: "Шинэ нууц үг оруулна уу" }],
    },
    {
      label: "Шинэ нууц үг давтах",
      name: "confirmpassword",
      type: "password",
      className: "col-span-12 mb-0",
      rules: [{ required: true, message: "Шинэ нууц үг давтан оруулна уу" }],
    },
  ];

  const handleSubmitChangePassword = (values) => {
    setLoading(true);
    instance({
      method: "put",
      url: "changepassword",
      data: {
        oldpassword: values.oldpassword,
        newpassword: values.newpassword,
      },
    })
      .then(() => {
        setOpenModal(false);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // form.resetFields()
  };
  return (
    <>
      <header className="px-5 md:px-10">
        <div className="border border-gray-200 flex flex-row justify-center bg-white shadow-lg rounded-full">
          <div
            className="px-10 py-2 relative flex items-center justify-center gap-4"
            onMouseOver={() => setDropdownOpen(true)}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <div className="text-right">
              <div className="text-black text-base">
                {authContext.state.userInfo?.profile?.lastName}
                {"  "}
                {authContext.state.userInfo?.profile?.firstName}
              </div>
              {/* <div className="text-mute text-xs">
                {authContext.state.userInfo?.profile.role}
              </div> */}
            </div>
            {/* <Badge count={1} offset={[10, 10]}> */}
              <AiOutlineUser className="text-2xl"></AiOutlineUser>
            {/* </Badge> */}
            {dropdownOpen && (
              <div
                className="flex flex-col absolute right-0 bottom-0 py-2 rounded z-20 bg-white shadow-xl translate-y-full whitespace-nowrap"
                tabIndex={-1}
              >
                <Link
                  className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0"
                  onClick={() => setOpenModal(true)}
                >
                  Нууц үг солих
                </Link>
                <Link
                  className="text-graytext p-4 hover:text-primary transition-all border-b last:border-b-0"
                  onClick={handleLogout}
                >
                  Гарах
                </Link>
              </div>
            )}

            <Button
              onClick={() => setIsOpenMenu(!isOpenMenu)}
              className="cursor-pointer  z-50  outline-none block md:hidden"
            >
              {isOpenMenu ? <CloseOutlined /> : <MenuOutlined />}
            </Button>
          </div>
        </div>
      </header>
      <Modal
        title={<div className="text-lg">Нууц үг солих</div>}
        open={openModal}
        onCancel={handleCloseModal}
        width={700}
        destroyOnClose
        footer={false}
      >
        <Form
          form={form}
          fields={fields}
          layout="vertical"
          onFinish={handleSubmitChangePassword}
          className="gap-4"
        />
        <div className="flex justify-end mt-4">
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            Хадгалах
          </Button>
        </div>
      </Modal>
      <div
        className={`fixed pb-12 w-full h-full flex flex-row z-[45] top-[100px] transition-all ${
          isOpenMenu ? "translate-x-0" : "translate-x-full"
        }`}
      >
        <div className="w-1/4" onClick={() => setIsOpenMenu(!isOpenMenu)}></div>
        <div className="w-3/4 overflow-y-scroll bg-gradient-to-r from-[#051212] to-[#0d292b]">
          <Menu
            onClick={() => setIsOpenMenu(!isOpenMenu)}
            defaultSelectedKeys={["menu-item-0"]}
            mode={"inline"}
            theme={"dark"}
            key={"menu"}
            inlineCollapsed={false}
            items={roleMenu}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
