import {
  BiUserCheck,
  BiIdCard,
  BiCog,
  BiGrid,
  BiBuilding,
  BiHorizontalLeft,
  BiHorizontalRight,
  BiCalendarPlus,
  BiDialpad,
  BiUserCircle,
  BiTransferAlt,
  BiBarChartAlt,
  BiDialpadAlt,
} from "react-icons/bi";
import { DashboardOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AuthContext } from "contexts";

export default [
  {
    label: "ДАТА",
    type: "group",
    role: ["Админ", "Удирдлага", "Менежер", "Ахлагч"],
    hospital: [1, 2],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/dashboard">Хянах самбар</Link>,
        icon: <DashboardOutlined size={16} className="inline text-[#F19920]" />,
        key: "dahboard",
        role: ["Админ", "Удирдлага", "Менежер", "Ахлагч"],
      },
    ],
  },
  {
    label: <Link to="/list">Жагсаалт</Link>,
    icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
    key: "list",
    role: ["Админ", "Удирдлага", "Менежер", "Ахлагч"],
  },
  {
    label: "Тохиргоо",
    type: "group",
    role: ["Админ"],
    hospital: [1, 2],
    icon: <BiIdCard size={16} className="inline text-[#F19920]" />,
    children: [
      {
        label: <Link to="/employee">Ажилчид</Link>,
        key: "employee",
        role: ["Админ", "Удирдлага", "Менежер"],
        hospital: [1, 2],
        icon: <BiUserCircle size={16} className="inline text-[#F19920]" />,
      },
    ],
  },
];
