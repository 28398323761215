import React, { useEffect, useState,useContext } from "react";
import {
  Button,
  Modal,
  Tag,
  Form as AntForm,
  Select,
  Input,
  Tooltip,
  
} from "antd";
import {
  SaveOutlined,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { AuthContext } from "contexts";
const EditableNoteCell = ({ text, row, handleNote }) => {
  const [note, setNote] = useState(text);

  return (
    <div className="flex items-center space-x-2 w-[200px]">
      <Input.TextArea
        value={note}
        style={{ fontSize: "11px" }}
        rows={2}
        onChange={(e) => {
          setNote(e.currentTarget.value);
        }}
      />
      <Button
        size="small"
        onClick={() => {
          handleNote(note, row.p_id);
        }}
      >
        <div className="flex items-center">
          <SaveOutlined />
        </div>
      </Button>
    </div>
  );
};
const EditableWhoCell = ({ text, row, handleWhom }) => {
  const [whom, setWhom] = useState(text);

  return (
    <div className="flex items-center space-x-2 w-[150px]">
      <Input
        value={whom}
        style={{ fontSize: "11px" }}
        rows={2}
        onChange={(e) => {
          setWhom(e.currentTarget.value);
        }}
      />
      <Button
        size="small"
        onClick={() => {
          handleWhom(whom, row.p_id);
        }}
      >
        <div className="flex items-center">
          <SaveOutlined />
        </div>
      </Button>
    </div>
  );
};
function ListPeople() {
  const authContext = useContext(AuthContext);
  const profile = authContext.state.userInfo.profile;
  const [data, setData] = useState([]);
  const [khoroos, setKhoroos] = useState([]);
  const [aimags, setAimags] = useState([]);
  const [sums, setSums] = useState([]);
  const [fSums, setFSums] = useState([]);
  const [sum, setSum] = useState("Бүгд");
  const [aimag, setAimag] = useState("Бүгд");
  const [khoroo, setKhoroo] = useState(profile.khorooId??-1);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [attitude, setAtt] = useState("Бүгд");
  const [support, setSuppor] = useState("Бүгд");
  const [searchText, setSearchText] = useState("");
  const [ovog, setOvog] = useState("");
    const [whom, setWhom] = useState("");
  // const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterForm] = AntForm.useForm();
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    // fetchData();
    fetchMaster()
  }, []);
  useEffect(()=>{
    filterSum()
  },[aimag])
  useEffect(() => {fetchData()}, [currentPage,khoroo,aimag, attitude, support, searchText,sum,ovog,whom]);
 const fetchMaster = ()=>{
  setLoading(true);
  instance({
    method: "get",
    url: `/main/khoroo`,
  })
    .then((res) => {
      let a = profile.role == "Админ" ? [{ value: -1, label: "Бүгд" }] : [];
     res.data.map((item) =>
       a.push({
         value: item.id,
         label: item.name,
       })
     );
     setKhoroos(a);
    })
    .catch((err) => {})
    .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/main/aimag`,
    })
      .then((res) => {
       let a = [{ value: "Бүгд", label: "Бүгд" }];

       res.data.map((item) =>
         a.push({
           value: item.name,
           label: item.name,
         })
       );
       setAimags(a);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
       instance({
      method: "get",
      url: `/main/sum`,
    })
      .then((res) => {
       let a = [{ value: "Бүгд", label: "Бүгд" }];

       res.data.map((item) =>
         a.push({
           value: item.sum,
           label: item.sum,
         })
       );
       setFSums(a)
       setSums(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
 }
 const filterSum=()=>{
 let s = sums?.filter((x) => x.aimag == aimag) || [];
 let a = [{ value: "Бүгд", label: "Бүгд" }];

 s.forEach((item) =>
   a.push({
     value: item.sum,
     label: item.sum,
   })
 );
 setSum("Бүгд")
 setFSums(a);
 }
  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/main/list?aimag=${aimag}&khoroo=${khoroo}&attitude=${attitude}&support=${support}&searchText=${
        searchText == "" ? "-1" : searchText
      }&ovog=${ovog == "" ? "-1" : ovog}&whom=${
        whom == "" ? "-1" : whom
      }&offset=${currentPage}&sum=${sum}`,
    })
      .then((res) => {
        setData(res.data.data);
        setTotalCount(res.data.total);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };
    const handleAtt = (value,id) => {
      instance({
        method: "put",
        url: `/main/att`,
        data: {
          attitude:value,
          p_id:id
        }
      })
        .then((res) => {
        })
        .catch((err) => {})
        .then(() => setLoading(false));
    };
   const handleSupport = (value, id) => {
     instance({
       method: "put",
       url: `/main/support`,
       data: {
         support: value,
         p_id: id,
       },
     })
       .then((res) => {})
       .catch((err) => {})
       .then(() => setLoading(false));
   };
    const handleNote = (value, id) => {
       instance({
         method: "put",
         url: `/main/note`,
         data: {
           note: value,
           p_id: id,
         },
       })
         .then((res) => {})
         .catch((err) => {})
         .then(() => setLoading(false));
     };
      const handleWhom = (value, id) => {
        instance({
          method: "put",
          url: `/main/whom`,
          data: {
            
            whom: value,
            p_id: id,
          },
        })
          .then((res) => {})
          .catch((err) => {})
          .then(() => setLoading(false));
      };
  const columns = [
    {
      title: "Овог",
      dataIndex: "lastName",
      key: "lastName",
      fixed: "left",
      width: 100,
    },
    {
      title: "Нэр",
      dataIndex: "firstName",
      key: "firstName",
      fixed: "left",
      width: 100,
    },
    {
      title: "Хороо",
      dataIndex: "khoroo",
      key: "khoroo",
      width: 80,
    },
    {
      title: "Нас Хүйс",
      dataIndex: "sex",
      key: "sex",
      width: 80,
    },
    // {
    //   title: "Нас",
    //   dataIndex: "age",
    //   key: "age",
    // },
    {
      title: "Утас",
      dataIndex: "mobile",
      key: "mobile",
      width: 80,
    },

    {
      title: "Байр",
      dataIndex: "apartment",
      key: "apartment",
      width: 100,
    },
    {
      title: "Төрсөн Газар",
      dataIndex: "tAimag",
      key: "tAimag",
      width: 110,
    },
    {
      title: "Төрсөн сум",
      dataIndex: "tSoum",
      key: "tSoum",
      width: 100,
    },
    // {
    //   title: "Төрсөн сум",
    //   dataIndex: "asum",
    //   key: "asum",
    // },
    {
      title: "Хаяг",
      dataIndex: "address2024",
      key: "address2024",
      width: 200,
    },
    {
      title: "Гишүүн эсэх",
      dataIndex: "member",
      key: "member",
      width: 80,
    },

    {
      title: "Хандлага",
      dataIndex: "attitude",
      key: "attitude",
      width: 120,
      render: (text, row) => {
        return (
          <Select
           key={"att-"+row.p_id}
            className="mb-0 w-[100px]"
            value={text}
            onChange={(e) => {
              handleAtt(e, row.p_id);
            }}
          >
            <Select.Option key={"Улаан"} value={"Улаан"}>
              <Tag color="red">Улаан</Tag>
            </Select.Option>
            <Select.Option key={"Цэнхэр"} value={"Цэнхэр"}>
              <Tag color="blue">Цэнхэр</Tag>
            </Select.Option>
            <Select.Option key={"Саарал"} value={"Саарал"}>
              <Tag>Саарал</Tag>
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: "Дэмжих эсэх",
      dataIndex: "support",
      key: "support",
      width: 140,
      render: (text, row) => {
        return (
          <Select
          key={"su-"+row.p_id}
            className="mb-0 w-[120px]"
            value={text}
            //  allowClear
            onChange={(e) => {
              handleSupport(e, row.p_id);
            }}
          >
            <Select.Option key={"Дэмжинэ"} value={"Дэмжинэ"}>
              <Tag color="green">Дэмжинэ</Tag>
            </Select.Option>
            <Select.Option key={"Дэмжихгүй"} value={"Дэмжихгүй"}>
              <Tag color="red">Дэмжихгүй</Tag>
            </Select.Option>
            <Select.Option key={"Шийдээгүй"} value={"Шийдээгүй"}>
              <Tag color="warning">Шийдээгүй</Tag>
            </Select.Option>
          </Select>
        );
      },
    },
    {
      title: "Хаанаас",
      dataIndex: "whom",
      key: "whom",
      width: 170,
      render: (text, row) => {
        return (
          <EditableWhoCell key={"w-"+row.p_id} text={text} row={row} handleWhom={handleWhom} />
        );
      },
    },
    {
      title: "Тайлбар",
      dataIndex: "note",
      key: "note",
      width: 200,
      render: (text, row) => {
        return (
          <EditableNoteCell  key={"n-"+row.p_id} text={text} row={row} handleNote={handleNote} />
        );
      },
    },

    // {
    //   title: "2020 Хаяг",
    //   dataIndex: "address2020",
    //   key: "address2024",
    //   width: 200,
    //   // width: 300,
    // },
  ];
   const exportToCSV = () => {
       try {
         setLoading(true);
    instance({
      method: "get",
      url: `/main/excellist?aimag=${aimag}&khoroo=${khoroo}&attitude=${attitude}&support=${support}&searchText=${
        searchText == "" ? "-1" : searchText
      }&ovog=${ovog == "" ? "-1" : ovog}&whom=${
        whom == "" ? "-1" : whom
      }&sum=${sum}`,
    })
      .then((res) => {
        const Heading = [
          { p_id: "Id" },
          { lastName: "Овог" },
          { firstName: "Нэр" },
          { khoroo: "Хороо" },
          { sex: "Нас Хүйс" },
          { mobile: "Утас" },
          { apartment: "Байр" },
          { tAimag: "Төрсөн аймаг" },
          { tSoum: "Төрсөн сум" },
          { member: "Намын гишүүн" },
          { address2024: "Хаяг" },
          { attitude: "Хандлага" },
          { support: "Дэмжих эсэх" },
          { note: "Тайлбар" },
        ];

        const ws = XLSX.utils.json_to_sheet(res.data, {
          header: Heading.map((h) => Object.keys(h)[0]),
        });

        // Add the headers
        XLSX.utils.sheet_add_aoa(
          ws,
          [Heading.map((h) => Object.values(h)[0])],
          { origin: "A1" }
        );

        // Calculate column widths
        const colWidths = data.reduce(
          (widths, row) => {
            return Object.keys(row).map((key, i) =>
              Math.max(
                widths[i] || 10,
                (row[key] ? row[key].toString().length : 0) + 2
              )
            );
          },
          Heading.map((h) => Object.values(h)[0].length + 2)
        );

        ws["!cols"] = colWidths.map((w) => ({ wch: w }));

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

        XLSX.writeFile(wb, `data_${khoroo}_khoroo.xlsx`);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
      
         setLoading(false);
       } catch (ex) {
         modal.error({
           title: "Анхаарна уу !",
           content: "Эксэл рүү гаргахад алдаа гарлаа.",
         });
         setLoading(false);
       
     } 
   };

  return (
    <div className="flex flex-col px-4 pb-4">
      <div className="col-span-6 md:flex items-center gap-2">
        {/* <Input
          className="mb-0 w-[150px]"
          placeholder={`Овог`}
          value={lastName}
          // allowClear
          onChange={(e) => {
            setLastName(String(e.target.value).trim());
          }}
          onPressEnter={() => {
            fetchData();
          }}
          style={{
            marginTop: 8,
            marginBottom: 8,
            display: "block",
          }}
        /> */}
        <Input
          className="mb-0 w-[150px]"
          placeholder={`РД  Нэрээр хайх`}
          // allowClear
          value={searchText}
          onChange={(e) => {
            setSearchText(String(e.target.value).trim());
          }}
          onPressEnter={() => {
            fetchData();
          }}
          style={{
            marginTop: 8,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Input
          className="mb-0 w-[150px]"
          placeholder="Овог хайх"
          // allowClear
          value={ovog}
          onChange={(e) => {
            setOvog(String(e.target.value).trim());
          }}
          onPressEnter={() => {
            fetchData();
          }}
          style={{
            marginTop: 8,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Input
          className="mb-0 w-[150px]"
          placeholder="Хаанаас хайх"
          value={whom}
          onChange={(e) => {
            setWhom(String(e.target.value).trim());
          }}
          onPressEnter={() => {
            fetchData();
          }}
          style={{
            marginTop: 8,
            marginBottom: 8,
            display: "block",
          }}
        />
        <Button
          onClick={() => {
            setOvog("");
            setSearchText("");
          }}
          size="small"
          style={{ width: 90 }}
        >
          Цэвэрлэх
        </Button>
        <Select
          // mode="multiple"
          size={"middle"}
          placeholder="Хороо сонгох"
          value={khoroo}
          style={{ width: "10%" }}
          options={khoroos}
          maxTagCount={"responsive"}
          onChange={(e) => {
            setKhoroo(e);
          }}
          maxTagPlaceholder={() => (
            <Tooltip>
              <span>Хороонууд</span>
            </Tooltip>
          )}
        />
        <Select
          size={"middle"}
          placeholder="Аймаг сонгох"
          value={aimag}
          onChange={(e) => {
            setAimag(e);
          }}
          style={{ width: "10%" }}
          options={aimags}
          maxTagCount={"responsive"}
          maxTagPlaceholder={() => (
            <Tooltip>
              <span>Аймгууд</span>
            </Tooltip>
          )}
        />
        <Select
          size={"middle"}
          placeholder="Сум сонгох"
          value={sum}
          onChange={(e) => {
            setSum(e);
          }}
          style={{ width: "10%" }}
          options={fSums}
          maxTagCount={"responsive"}
          maxTagPlaceholder={() => (
            <Tooltip>
              <span>сумууд</span>
            </Tooltip>
          )}
        />
        <Select
          size={"middle"}
          placeholder="Хандлага"
          style={{ width: "10%" }}
          value={attitude}
          onChange={(e) => {
            setAtt(e);
          }}
        >
          <Select.Option key={"Бүгд"} value={"Бүгд"}>
            Бүгд
          </Select.Option>
          <Select.Option key={"Улаан"} value={"Улаан"}>
            <Tag color="red">Улаан</Tag>
          </Select.Option>
          <Select.Option key={"Цэнхэр"} value={"Цэнхэр"}>
            <Tag color="blue">Цэнхэр</Tag>
          </Select.Option>
          <Select.Option key={"Саарал"} value={"Саарал"}>
            <Tag>Саарал</Tag>
          </Select.Option>
        </Select>

        <Select
          // mode="multiple"
          size={"middle"}
          placeholder="Дэмжих эсэх"
          value={support}
          onChange={(e) => {
            setSuppor(e);
          }}
          style={{ width: "10%" }}
          // options={columnNames()}
        >
          <Select.Option key={"Бүгд"} value={"Бүгд"}>
            Бүгд
          </Select.Option>
          <Select.Option key={"Дэмжинэ"} value={"Дэмжинэ"}>
            <Tag color="green">Дэмжинэ</Tag>
          </Select.Option>
          <Select.Option key={"Дэмжихгүй"} value={"Дэмжихгүй"}>
            <Tag color="red">Дэмжихгүй</Tag>
          </Select.Option>
          <Select.Option key={"Шийдээгүй"} value={"Шийдээгүй"}>
            <Tag color="warning">Шийдээгүй</Tag>
          </Select.Option>
        </Select>
        <Button
          type="primary"
          onClick={() => fetchData()}
          icon={<SearchOutlined />}
          size="medium"
          style={{ width: 90 }}
        >
          Хайх
        </Button>
        <Button
          //  icon={<FaDownload />}
          onClick={() => {
            exportToCSV();
          }}
        >
          Татах
        </Button>
      </div>
      <MainTable
        dataTable={data}
        columns={columns}
        setCurrentPage={setCurrentPage}
        loading={loading}
        pagination={{
          total: totalCount,
        }}
        pageSize={50}
        title={"Нэрс"}
        scroll={{ y: "calc(100vh - 320px)" }}
      />
      {contextHolder}
    </div>
  );
}

export default ListPeople;
