import { Pie } from "@ant-design/charts";
import React from "react";

function PieChart({ data }) {
  const config = {
    appendPadding: 10,
    data: data ? data : [],
    angleField: "cnt",
    colorField: "name",
    radius: 0.9,
    legend: {
      position: "top",
    },
    label: {
      type: "outer",
      offset: "-30%",
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };
  return (
    <div className="flex-1">
      <Pie {...config} />
    </div>
  );
}
export default PieChart;
