import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Modal,
  Tag,
  Form as AntForm,
  Select,
  Input,
  Tooltip,
} from "antd";
import { SaveOutlined, SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { Form, MainTable } from "components";
import { instance } from "utils/axios";
import PieChart from "./PieChart";
import ColumnChart from "./ColumnChart";
import { AuthContext } from "contexts";
import CountTable from "./Service/CountTable";
import KhorooTable from "./Service/KhorooTable";
import WhomTable from "./Service/WhomTable";
const Dashboard = ({}) => {
  const authContext = useContext(AuthContext);
  const profile = authContext.state.userInfo.profile;
  // const authContext = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [khoroos, setKhoroos] = useState([]);
  const [khoroo, setKhoroo] = useState([profile.khorooId ?? -1]);
  const [aimags, setAimags] = useState([]);
  const [aimag, setAimag] = useState("Бүгд");
  const [section, setSection] = useState(profile.sectionId??-1);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  let sec1 = {
    value: 1,
    label: "3, 9, 16, 20, 21",
  };
  let sec2 = {
    value: 2,
    label: "22, 23, 11, 17, 24",
  };
  let sec3 = {
    value: 3,
    label: "1, 2, 15, 18, 19",
  };
  let sec4 = {
    value: 4,
    label: "4, 5, 6, 7, 8",
  };
  let sec5 = {
    value: 5,
    label: "10, 12, 13, 14, 25",
  };
  let sec6 ={
     value: 6,
    label: "11,22,17,18",
  }

  useEffect(() => {
   
    if (profile.sectionId == 1) {
      setOptions([sec1]);
    } else if (profile.sectionId == 2) {
      setOptions([sec2]);
    }
    if (profile.sectionId == 3) {
      setOptions([sec3]);
    }
    if (profile.sectionId == 4) {
      setOptions([sec4]);
    }
    if (profile.sectionId == 5) {
      setOptions([sec5]);
    }
    if (profile.sectionId == 6) {
      setOptions([sec6]);
    }
    if (profile.role == "Админ") {
      setOptions([{ value: -1, label: "Бүгд" }, sec1, sec2, sec3, sec4, sec5,sec6]);
    }
    fetchData();
    fetchMaster();
  }, []);
  useEffect(() => {
    fetchData();
    fetchTableData();
  }, [khoroo, aimag]);
  const fetchMaster = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/main/khoroo`,
    })
      .then((res) => {
        let a = profile.role == "Админ" ? [{ value: -1, label: "Бүгд" }] : [];
        res.data.map((item) =>
          a.push({
            value: item.id,
            label: item.name,
          })
        );
        setKhoroos(a);
      })

      .catch((err) => {})
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/main/aimag`,
    })
      .then((res) => {
        let a = [{ value: "Бүгд", label: "Аймгууд/Бүгд/" }];

        res.data.map((item) =>
          a.push({
            value: item.name,
            label: item.name,
          })
        );
        setAimags(a);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "post",
      url: `/main/dashboard`,
      data: {
        khoroo: khoroo.length>0 ?khoroo: [profile.khorooId??-1],
        aimag: aimag,
      },
    })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };
  const fetchTableData = () => {
    setLoading(true);
    instance({
      method: "post",
      url: `/main/dashboard/table`,
      data: {
       khoroo: khoroo.length>0 ?khoroo: [profile.khorooId??-1],
        aimag: aimag,
      },
    })
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .then(() => setLoading(false));
  };

  return (
    <div className="px-1  md:px-5">
      <div className="grid grid-cols-3 md:grid-cols-6 gap-5 ">
        <Select
          placeholder="Хэсэг сонгох"
          value={section}
          className="mb-0 w-[185px]"
          onChange={(e) => {
            if (e == 1) {
              setKhoroo([3, 9, 16, 20, 21]);
            } else if (e == 2) {
              setKhoroo([22, 23, 11, 17, 24]);
            } else if (e == 3) {
              setKhoroo([1, 2, 15, 18, 19]);
            } else if (e == 4) {
              setKhoroo([4, 5, 6, 7, 8]);
            } else if (e == 5) {
              setKhoroo([10, 12, 13, 14, 25]);
            } else if (e == 6) {
              setKhoroo([11,22,17,18]);
            } else if (e == -1) {
              setKhoroo([-1]);
            }
          }}
          options={options}
        />
        <Select
          mode="multiple"
          allowClear
          placeholder="Хороо сонгох"
          value={khoroo}
          className="mb-0 w-[185px]"
          options={khoroos}
          maxTagCount={"responsive"}
          onChange={(e) => {
           
            setKhoroo(e);
          }}
          maxTagPlaceholder={() => (
            <Tooltip>
              <span> {khoroo.join(", ")}</span>
            </Tooltip>
          )}
        />
        <Select
          placeholder="Аймаг сонгох"
          value={aimag}
          className="mb-0 w-[185px]"
          onChange={(e) => {
            setAimag(e);
          }}
          options={aimags}
          maxTagCount={"responsive"}
          maxTagPlaceholder={() => (
            <Tooltip>
              <span>Аймгууд</span>
            </Tooltip>
          )}
        />
      </div>

      <div className="grid grid-cols-12 gap-5 mt-5"></div>
      <div className="grid grid-cols-24 gap-2">
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Нийт тоо</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.Total ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Улаан</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.redCount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-blue-700 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Цэнхэр</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.blueCount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-white flex items-center gap-6">
          <div>
            <div className="text-base text-muted"> Саарал</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.grayCount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-orange-400 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Дэмжинэ</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.suppCount ?? 0)}
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-4 shadow p-4 rounded-lg bg-green-700 flex items-center gap-6">
          <div>
            <div className="text-base text-muted">Дэмжихгүй</div>
            <div className="font-small text-xl">
              {Intl.NumberFormat().format(data.sumData?.notSuppCount ?? 0)}
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-5 mt-5">
        {/* <ColumnChart
          data={data?.dayData}
          className="col-span-12"
          alias="Тоо/Өдөр/"
          xField="tDay"
          yField="cnt"
          start={0}
        /> */}
         <WhomTable
          data={tableData?.whomData}
          className="col-span-12 "
          title="Хаанаас"
        />
        <div className="col-span-12 md:col-span-6 ">
          <PieChart data={tableData?.attitude} />
        </div>
        <div className="col-span-12 md:col-span-6 ">
          <PieChart data={tableData?.support} />
        </div>
        <CountTable
          data={tableData?.attitude}
          className="col-span-12 md:col-span-6 "
          title="Хандлага"
        />
        <CountTable
          data={tableData?.support}
          className="col-span-12 md:col-span-6 "
          title="Дэмжлэг"
        />
        <KhorooTable
          data={tableData?.khorooData}
          className="col-span-12 "
          title="Хороо"
        />
       
      </div>
    </div>
  );
};

export default Dashboard;
