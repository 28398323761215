import React, { useState, useEffect } from "react";
import { Input, Form, Button } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "contexts";
import { instance } from "utils/axios";
// import bgImage from "assets/images/background.png";
// import logo from "assets/images/logo.jpg";
import moment from "moment";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import axios from "axios";
import QueryString from "qs";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const authContext = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const axiosInstance = axios.create();
  axiosInstance.defaults.maxRedirects = 0; // Set to 0 to prevent automatic redirects
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log("resdasdad", error);
      if (error.response && [301, 302].includes(error.response.status)) {
        const redirectUrl = error.response.headers.location;
        return axiosInstance.post(redirectUrl);
      }
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    const formData = new FormData();
    formData.append("luci_username", "admin");
    formData.append("luci_password", "111111");
    const data = QueryString.stringify({
      luci_username: "admin",
      luci_password: "111111",
    });
    // axiosInstance.post('http://192.168.1.83/cgi-bin/luci', data)
    fetch("http://192.168.1.83/cgi-bin/luci", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Accept: "*/*",
      },
      body: data,
    })
      .then((res) => console.log("fetch res", res))
      .catch((err) => console.log("fetch err", err));

    axios({
      url: "http://192.168.1.83/cgi-bin/luci",
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
      },
      maxRedirects: 4,
      data: data,
    })
      .then((res) => {
        console.log("luci_response", res);
      })
      .catch((err) => {
        console.log("luci_error", err);
      });
  }, []);

  useEffect(() => {
    if (authContext.state.token) {
      if (authContext.state.userInfo.profile.role != "Админ")
        navigate("/list", { replace: true });
      else {
        navigate("/", { replace: true });
      }
    }
  }, [authContext.state]);

  const handleSubmit = (values) => {
    setLoading(true);
    instance({
      method: "post",
      url: `/login`,
      data: values,
    })
      .then((res) => {
        authContext.action.login(res.data.token, res.data);
      })
      .catch((err) => {
        form.setFields([
          {
            name: "email",
            errors: [""],
          },
          {
            name: "password",
            errors: [err.response.data.value],
          },
        ]);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const handleSubmitReset = (values) => {
    setLoading(true);
    instance({
      method: "post",
      url: "auth/resetpassword",
      data: {
        ...values,
      },
    })
      .then((res) => {
        setIsReset(!isReset);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  };

  const handleFormChange = () => {
    form.setFields([
      {
        name: "email",
        errors: [],
      },
      {
        name: "password",
        errors: [],
      },
    ]);
  };

  return (
    <div className="flex h-screen">
      <div className="hidden md:block flex-1 relative bg-slate-200">
        <div className=" absolute inset-0 bg-no-repeat  flex justify-center items-end pb-4 bg-contain bg-center"></div>
      </div>
      <div className="flex-1 flex flex-col p-4 md:p-0 bg-gray-100">
        <div className="flex-1 flex justify-center flex-col">
          <div className="py-10 grid grid-cols-12">
            <div className="col-span-12 xl:col-start-4 xl:col-span-6 sm:col-start-3 sm:col-span-8 md:col-start-5 md:col-span-4">
              <div className="flex flex-col items-center">
                <h1>Тавтай морилно уу.</h1>
                {/* <img src={"logo.png"} alt="logo" width={250} /> */}
              </div>
              {!isReset ? (
                <Form
                  form={form}
                  className="md:mt-10"
                  onFinish={handleSubmit}
                  onValuesChange={handleFormChange}
                  layout="vertical"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: "Нэвтрэх нэр оруулна уу!" },
                    ]}
                  >
                    <Input
                      size="middle"
                      prefix={<UserOutlined />}
                      placeholder="Нэвтрэх нэр"
                      className="py-3 rounded-lg"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: "Нууц үг код оруулна уу!" },
                    ]}
                  >
                    <Input.Password
                      size="middle"
                      prefix={<LockOutlined />}
                      type="password"
                      placeholder="Нууц үг"
                      className="py-3 rounded-lg"
                    />
                  </Form.Item>
                  {/* <Form.Item>
                    <div className="flex justify-end">
                      <Link className="underline" onClick={() => setIsReset(!isReset)}>
                        Нууц үг сэргээх
                      </Link>
                    </div>
                  </Form.Item> */}
                  <Form.Item>
                    <Button
                      loading={loading}
                      disabled={loading}
                      block
                      type="primary"
                      size="middle"
                      htmlType="submit"
                      className="bg-[#64308c] border-none hover:bg-[#f15a2a] h-11 rounded-lg"
                    >
                      Нэвтрэх
                    </Button>
                  </Form.Item>
                </Form>
              ) : (
                <>
                  <div className="text-2xl text-center text-primary mt-5 font-bold">
                    Нууц үг сэргээх
                  </div>
                  <Form
                    form={form}
                    className="md:mt-8"
                    onFinish={handleSubmitReset}
                    layout="vertical"
                  >
                    <Form.Item
                      label="И-Мэйл хаяг"
                      name="email"
                      className="mb-3"
                      rules={[
                        { required: true, message: "И-Мэйл хаяг оруулна уу!" },
                      ]}
                    >
                      <Input
                        size="middle"
                        placeholder="И-Мэйл хаяг"
                        className="py-3 rounded-lg"
                      />
                    </Form.Item>
                    <div className="mb-4 text-xs text-gray-500 p-3 border border-orange-200 rounded-lg bg-orange-100">
                      Та зөвхөн өөрийн бүртгэлтэй и-мэйл хаягийг оруулснаар
                      тухайн и-мэйл хаягт шинэ нууц үг илгээх болно
                    </div>
                    <Form.Item>
                      <div className="flex justify-end">
                        <Link
                          className="underline"
                          onClick={() => setIsReset(!isReset)}
                        >
                          Нэвтрэх хэсэг рүү буцах
                        </Link>
                      </div>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        loading={loading}
                        disabled={loading}
                        block
                        type="primary"
                        size="middle"
                        htmlType="submit"
                        className="bg-[#097947] border-none hover:bg-[#469f43] h-11 rounded-lg"
                      >
                        Илгээх
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
